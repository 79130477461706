import React, { useEffect, useMemo, forwardRef } from 'react'
// import MaterialTable, { MTableToolbar } from 'material-table'
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
import { MRT_Localization_DE } from 'material-react-table/locales/de';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Typography from "@material-ui/core/Typography";
import firebase from '../firebase'
import { withRouter } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'

const MyNewTitle = ({ text, variant }) => (
  <Typography
    variant={variant}
    style={{
      whiteSpace: "wrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: "gray"
    }}
  >
    {text}
  </Typography>
);

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(800 + theme.spacing.unit * 3 * 2)]: {
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 6,
    marginBottom: theme.spacing.unit * 6,
    backgroundColor: 'GhostWhite',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 0}px ${theme.spacing.unit * 3}px`,
  },
  mypaper: {
    padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 1}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    marginTop: theme.spacing.unit * 0,
  },
  saveprint: {
    marginTop: theme.spacing.unit * 2,
  },
  form: {
    width: '90%',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
  },
  formControl: {
    margin: theme.spacing.unit * 30,
  },
})

function OrgaPage(props) {
  //const { classes } = props

  const [state, setState] = React.useState({
    data: []
/*     data: [
      { category:'Start', work: 'Startort/-Zeit festlegen', person: 'KD', deadline: '01.03.2024', done: false  },
      { category:'Start', work: 'Nenngeld / Teilnahmegebühr festlegen', person: 'KD', deadline: '01.01.2024', done: false  },
      { category:'Anmeldung', work: 'Anmeldeformular online/Blanko erstellen', person: 'Stefan', deadline: '01.01.2024', done: false  },
      { category:'Anmeldung', work: 'Nennbestätigungen auswerten (Teilnehmer, Teams, Kinder)', person: 'Anja', deadline: '31.03.2024', done: false  },
      { category:'Ziel', work: 'Urkundenvorlagen erstellen (Normal/Junior/Kids)', person: 'Stefan', deadline: '01.01.2024', done: false  },
      { category:'Fahrermappe', work: 'Feedbackvorlage', person: 'Stefan', deadline: '01.01.2024', done: false  },
      { category:'Anmeldung', work: 'Nennbestätigungen als Pdf drucken', person: 'Stefan', deadline: '3/31/2024', done: false  },
      { category:'Fahrermappe', work: 'Fahrerkarte Sa/So als Pdf drucken', person: 'Stefan', deadline: '01.01.2024', done: false  },
      { category:'Fahrermappe', work: 'Nominelle und techn. Abnahme als Pdf drucken', person: 'Stefan', deadline: '01.01.2024', done: false  },
      { category:'Ziel', work: 'Urkundenpapier bereitstellen', person: 'Anja', deadline: '01.01.2024', done: false  },
      { category:'Ziel', work: 'Drucker bereitstellen', person: 'Guido', deadline: '01.01.2024', done: false  },
      { category:'Anmeldung', work: 'Teilnehmerdaten in Auswertungsprogramm einpflegen', person: 'Stefan', deadline: '31.03.2024', done: false  },
    ],
 */  });

  //const [open, setOpen] = React.useState(false);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  useEffect(() => {
    console.log("OrgaPage-loaded");

    async function login() {
      try {
        await firebase.login('Testkonto@bjfr-anmeldung.de', 'Testkonto')

      } catch (error) {
        alert(error.message)
      }
    }

    async function getOrgaData() {
      const result = await firebase.getOrgaData();
      console.log("getOrgData: ", result);
      return result;
    }

    login().then(res => getOrgaData()).then(res => {
      console.log("res.data: ", res);
      if (res) {
        console.log("setState: ", res);
        res.forEach(element => {
          try {
            element.deadline = element.deadline.toDate();
          } catch (error) {

          }
        });
        setState({
          data: res
        });
      }
      //saveData();
    });

    /*     async function saveData() {
          try {
            //console.log("Data", state.data);
    
            const data = [
              { category: 'Start', work: 'Startort/-Zeit festlegen', person: 'KD', deadline: new Date(), done: false },
              { category: 'Start', work: 'Nenngeld / Teilnahmegebühr festlegen', person: 'KD', deadline: new Date(), done: false },
              { category: 'Anmeldung', work: 'Anmeldeformular online/Blanko erstellen', person: 'Stefan', deadline: new Date(), done: false },
              { category: 'Anmeldung', work: 'Nennbestätigungen auswerten (Teilnehmer, Teams, Kinder)', person: 'Anja', deadline: new Date(), done: false },
              { category: 'Ziel', work: 'Urkundenvorlagen erstellen (Normal/Junior/Kids)', person: 'Stefan', deadline: new Date(), done: false },
              { category: 'Fahrermappe', work: 'Feedbackvorlage', person: 'Stefan', deadline: new Date(), done: false },
              { category: 'Anmeldung', work: 'Nennbestätigungen als Pdf drucken', person: 'Stefan', deadline: new Date(), done: false },
              { category: 'Fahrermappe', work: 'Fahrerkarte Sa/So als Pdf drucken', person: 'Stefan', deadline: new Date(), done: false },
              { category: 'Fahrermappe', work: 'Nominelle und techn. Abnahme als Pdf drucken', person: 'Stefan', deadline: new Date(), done: false },
              { category: 'Ziel', work: 'Urkundenpapier bereitstellen', person: 'Anja', deadline: new Date(), done: false },
              { category: 'Ziel', work: 'Drucker bereitstellen', person: 'Guido', deadline: new Date(), done: false },
              { category: 'Anmeldung', work: 'Teilnehmerdaten in Auswertungsprogramm einpflegen', person: 'Stefan', deadline: new Date(), done: false },
            ]
            await firebase.updateOrgData(data)
          } catch (error) {
            alert(error.message)
          }
        }
     */
  }, []);

  //const columns = state.columns;
  const columns = useMemo(
    () => [
      // { header: 'Index', field: 'index', type: 'numeric', render: rowData => rowData.tableData.id + 1, hidden: true },
      // { header: 'Rubrik', field: 'category', accessorKey: 'category' },
      // { header: 'Aufgabe', field: 'work' },
      // { header: 'Verantwortlich', field: 'person', accessorKey: 'category' },
      // { header: 'Termin', field: 'deadline', type: 'date', dateSetting: { locale: "de-DE", format: "dd.MM.yyyy" } },
      // { header: 'Anmerkung', field: 'comment' },
      // { header: "Erledigt", field: "done", type: 'boolean' }
      {
        accessorKey: 'index',
        header: 'Index'
      },
      {
        accessorKey: 'category',
        header: 'Rubrik'
      },
      {
        accessorKey: 'work',
        header: 'Aufgabe'
      },
      {
        accessorKey: 'person',
        header: 'Verantwortlich'
      },
      {
        accessorKey: 'deadline',
        header: 'Termin',
        accessorFn: (row) => new Date(row.deadline), //convert to Date for sorting and filtering
        Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), //render Date as a string
      },
      {
        accessorKey: 'comment',
        header: 'Anmerkung'
      },
      {
        accessorKey: 'done',
        header: 'Erledigt',
        Cell: ({ cell }) => cell.getValue()?.toString(),
        editVariant: 'select',
        editSelectOptions: ['true', 'false'], // Replace with your state options
        muiEditTextFieldProps: {
            select: true,
        },
},
    ],
    [],
  );
  const data = state.data;

  const openDeleteConfirmModal = (row) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      //deleteUser(row.original.id);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableEditing: true,
    editDisplayMode: 'modal',
    createDisplayMode: 'modal',
    onCreatingRowSave: ({ table, values }) => {
      //validate data
      //save data to api
      table.setEditingRow(null); //exit editing mode
    },
    onEditingRowCancel: () => {
      //clear any validation errors
    },
    muiEditRowDialogProps: {
      //optionally customize the dialog
    },
    renderEditRowDialogContent: ({ internalEditComponents, row, table }) => {
      //optionally, completely override the render of the dialog content
      //use `internalEditComponents` to render the generated text fields, or completely render your own form and inputs
    },
    enableBottomToolbar: true,
    enableGrouping: true,
    enableStickyHeader: true,
    initialState: {
      density: 'compact',
      expanded: true, //expand all groups by default
      grouping: ['category'], //an array of columns to group by by default (can be multiple)
      pagination: { pageIndex: 0, pageSize: 20 },
      sorting: [{ id: 'category', desc: false }], //sort by state by default
      columnVisibility: { index: false }
    },
    muiToolbarAlertBannerChipProps: { color: 'primary' },
    muiTableContainerProps: { sx: { maxHeight: 700 } },
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        variant="contained"
        onClick={() => {
          table.setCreatingRow(true); //simplest way to open the create row modal with no default values
          //or you can pass in a row object to set default values with the `createRow` helper function
          // table.setCreatingRow(
          //   createRow(table, {
          //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
          //   }),
          // );
        }}
      >
        Neue Aufgabe
      </Button>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    localization: MRT_Localization_DE,
  });

  return <MaterialReactTable table={table} />;
  
  // return (
  //   <MaterialReactTable
  //     //title="Aufgabenliste 2024"
  //     title={<MyNewTitle variant="h6" text="Checkliste Rallye 2024" />}
  //     components={{
  //       Toolbar: (props) => (
  //         <div
  //           style={{
  //             alignItems: "center",
  //             justifyContent: "center",
  //             display: "flow"
  //           }}
  //         >
  //           {/* <MTableToolbar {...props} /> */}
  //         </div>
  //       )
  //     }}
  //     icons={tableIcons}
  //     localization={{
  //       body: {
  //         emptyDataSourceMessage: 'Keine Einträge',
  //         addTooltip: 'Hinzufügen',
  //         deleteTooltip: 'Löschen',
  //         editTooltip: 'Bearbeiten',
  //         filterRow: {
  //           filterTooltip: 'Filter'
  //         },
  //         editRow: {
  //           deleteText: 'Diese Zeile wirklich löschen?',
  //           cancelTooltip: 'Abbrechen',
  //           saveTooltip: 'Speichern'
  //         }
  //       },
  //       grouping: {
  //         placeholder: 'Spalten ziehen ...',
  //         groupedBy: 'Gruppiert nach:'
  //       },
  //       header: {
  //         actions: 'Aktionen'
  //       },
  //       pagination: {
  //         labelDisplayedRows: '{from}-{to} von {count}',
  //         labelRowsSelect: 'Zeilen',
  //         labelRowsPerPage: 'Zeilen pro Seite:',
  //         firstAriaLabel: 'Erste Seite',
  //         firstTooltip: 'Erste Seite',
  //         previousAriaLabel: 'Vorherige Seite',
  //         previousTooltip: 'Vorherige Seite',
  //         nextAriaLabel: 'Nächste Seite',
  //         nextTooltip: 'Nächste Seite',
  //         lastAriaLabel: 'Letzte Seite',
  //         lastTooltip: 'Letzte Seite'
  //       },
  //       toolbar: {
  //         addRemoveColumns: 'Spalten hinzufügen oder löschen',
  //         nRowsSelected: '{0} Zeile(n) ausgewählt',
  //         showColumnsTitle: 'Zeige Spalten',
  //         showColumnsAriaLabel: 'Zeige Spalten',
  //         exportTitle: 'Export',
  //         exportAriaLabel: 'Export',
  //         exportName: 'Export als CSV',
  //         searchTooltip: 'Suche',
  //         searchPlaceholder: 'Suche'
  //       }
  //     }}
  //     options={{
  //       search: true, exportButton: true, actionsColumnIndex: 0, pageSize: 15, pageSizeOptions: [15, 30, 45],
  //       headerStyle: { backgroundColor: 'White' }, toolbarButtonAlignment: 'left',
  //       rowStyle: x => {
  //         if (x.tableData.id % 2) {
  //           return { backgroundColor: "whitesmoke" }
  //         }
  //       },
  //       enableGrouping: true,
  //       manualGrouping: true,
  //       initialState: {
  //         grouping: ['category', 'person'], //group by location and department by default and expand grouped rows
  //         expanded: true, //show grouped rows by default
  //         density: 'compact',
  //       }
  //     }}
  //     columns={state.columns}
  //     data={state.data}
  //     editable={{
  //       onRowAdd: (newData) =>
  //         new Promise((resolve) => {
  //           setTimeout(() => {
  //             resolve();
  //             setState((prevState) => {
  //               const data = [...prevState.data];
  //               data.push(newData);
  //               firebase.updateOrgData(data)
  //               return { ...prevState, data };
  //             });
  //           }, 600);
  //         }),
  //       onRowUpdate: (newData, oldData) =>
  //         new Promise((resolve) => {
  //           setTimeout(() => {
  //             resolve();
  //             if (oldData) {
  //               setState((prevState) => {
  //                 const data = [...prevState.data];
  //                 data[data.indexOf(oldData)] = newData;
  //                 firebase.updateOrgData(data)
  //                 return { ...prevState, data };
  //               });
  //             }
  //           }, 600);
  //         }),
  //       onRowDelete: (oldData) =>
  //         new Promise((resolve) => {
  //           setTimeout(() => {
  //             resolve();
  //             setState((prevState) => {
  //               const data = [...prevState.data];
  //               data.splice(data.indexOf(oldData), 1);
  //               firebase.updateOrgData(data)
  //               return { ...prevState, data };
  //             });
  //           }, 600);
  //         }),
  //     }}
  //   />
  // );
}

export default withRouter(withStyles(styles)(OrgaPage))