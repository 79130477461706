import React, { useEffect, useMemo, forwardRef } from 'react'
// import MaterialTable, { MTableToolbar } from 'material-table'
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { MRT_Localization_DE } from 'material-react-table/locales/de';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
//import Typography from "@material-ui/core/Typography";
import firebase from '../../firebase'
import { withRouter } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(800 + theme.spacing(3*2))]: {
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    backgroundColor: 'GhostWhite',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(0)}px ${theme.spacing(3)}px`,
  },
  mypaper: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
  avatar: {
    margin: theme.spacing,
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    marginTop: theme.spacing(0),
  },
  saveprint: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: '90%',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(30),
  },
})

function Station12(props) {
  //const { classes } = props

  const [state, setState] = React.useState({
    data: []
 });

  useEffect(() => {
    console.log("Station12-loaded");

    async function login() {
      try {
        await firebase.login('Station1.1@bjfr-anmeldung.de', 'station11!')
        props.history.replace('/stationen/station1-2')
      } catch (error) {
        alert(error.message)
      }
    }

    async function getStationData() {
      //saveData();
      const result = await firebase.getStationData('nKEVszsk9B9kTVBuCoxW');
      console.log("getStationgData: ", result);
      return result;
    }

    login().then(res => getStationData()).then(res => {
      console.log("res.data: ", res);
      if (res) {
        console.log("setState: ", res);
        setState({
          data: res
        });
      }      
    });

    async function saveData() {
          try {
            //console.log("Data", state.data);
    
            const data = [
              { nr: 1, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 2, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 3, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 4, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 5, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 6, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 7, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 8, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 9, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 10, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 11, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 12, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 13, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 14, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 15, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 16, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 17, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 18, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 19, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 20, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 21, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 22, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 23, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 24, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 25, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 26, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 27, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 28, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 29, name: 'Name1/Name2', points: null, time: null, value: null},
              { nr: 30, name: 'Name1/Name2', points: null, time: null, value: null},
            ]
            await firebase.updateStationData(data, 'nKEVszsk9B9kTVBuCoxW')
          } catch (error) {
            alert(error.message)
          }
        }

  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'nr',
        header: 'Team',
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: 'name',
        header: 'Name',
        enableEditing: false,
      },
      {
        accessorKey: 'points',
        header: 'Punkte'
      },
      {
        accessorKey: 'time',
        header: 'Zeit'
      },
      {
        accessorKey: 'value',
        header: 'Wert'
      }
    ],
    [],
  );
  const data = state.data;

  //UPDATE action
  const handleSaveUser = async ({ values, table }) => {
 /*    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({}); */
    state.data.forEach(item=>{
      if(item.nr===values.nr){
        item.points=values.points;
        item.time=values.time;
        item.value=values.value;
      }
    })

    console.log("updateStationData", state.data);
    await firebase.updateStationData(state.data, 'nKEVszsk9B9kTVBuCoxW');
    table.setEditingRow(null); //exit editing mode
  };

    //DELETE action
    const openDeleteConfirmModal = (row) => {
      if (window.confirm('Are you sure you want to delete this user?')) {
        //deleteUser(row.original.id);
      }
    };

  const table = useMaterialReactTable({
    columns,
    data,
    enableEditing: true,
    enableTopToolbar: true,
    editDisplayMode: 'modal',
    createDisplayMode: 'modal',
    //onCreatingRowCancel: () => setValidationErrors({}),
    //onCreatingRowSave: handleCreateUser,
    //onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveUser,
    //optionally customize modal content
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Create New User</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    //optionally customize modal content
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h4">Teamergebnis</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
{/*         <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
 */}      
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <h4 >Ergebnisse Tag 1 Station 2 Hallo { firebase.getCurrentUsername() }</h4>
    ),
    state: {
/*       isLoading: isLoadingUsers,
      isSaving: isCreatingUser || isUpdatingUser || isDeletingUser,
      showAlertBanner: isLoadingUsersError,
      showProgressBars: isFetchingUsers, */
    },
    localization: MRT_Localization_DE
  });

  return <MaterialReactTable table={table} />;  
}

export default withRouter(withStyles(styles)(Station12))