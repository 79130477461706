import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="main-footer">
      <div className="container">
        <div className="navbar-default navbar-fixed-bottom">
            <div className="text-center footer">&copy;{new Date().getFullYear()} Brandenburgische Jugend- und Familienrallye.&nbsp;&nbsp;&nbsp;<a href="/#/datenschutz">Datenschutzerklärung</a>&nbsp;&nbsp;&nbsp;<a href="/#/impressum">Impressum</a></div>
        </div>
     </div>
    </div>
  );
}

export default Footer;