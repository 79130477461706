import React from "react";

function Impressum() {
  return (
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
                <h1>Impressum</h1>

                <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                <p>Verkehrswacht Oranienburg e.V.<br />
                    Walther-Bothe-Stra&szlig;e 75<br />
                    16515 Oranienburg</p>
                
                <p><strong>Vertreten durch:</strong><br />
                    Klaus-Dieter Berndt</p>
                
                <h2>Kontakt</h2>
                <p>Telefon: +49 (0) 3301 - 58920<br />
                Telefax: +49 (0) 3301 - 589215<br />
                E-Mail: org&#64;verkehrswacht-oranienburg&#46;de</p>
                
                <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
                
                <p>Quelle: <a href="https://www.e-recht24.de">eRecht24</a></p>
        </div>
      </div>
    </div>
  );
}

export default Impressum;