import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firebase-firestore'

const config = {
    apiKey: "AIzaSyAfalxhcQZ4SHHxykdvZDESgyCn1LidWXw",
    authDomain: "testproject-223616.firebaseapp.com",
    databaseURL: "https://testproject-223616.firebaseio.com",
    projectId: "testproject-223616",
    storageBucket: "testproject-223616.appspot.com",
    messagingSenderId: "713740988592",
    appId: "1:713740988592:web:0e9ae4055d9b55bf284b75"
}

class Firebase {
	constructor() {
		app.initializeApp(config)
		this.auth = app.auth()
		this.db = app.firestore()
		this.googleProvider = new app.auth.GoogleAuthProvider();
	}

	login(email, password) {
		return this.auth.signInWithEmailAndPassword(email, password)
	}

	logout() {
		console.log("Logout", this.auth.currentUser);
		return this.auth.signOut()
	}

	async register(name, email, password) {
		await this.auth.createUserWithEmailAndPassword(email, password)
		return this.auth.currentUser.updateProfile({
			displayName: name
		})
	}

	passwordreset(email){
		var auth = this.auth;
		auth.languageCode = 'de';
		auth.sendPasswordResetEmail(email).then(function() {
			alert('Eine E-Mail wurde an ' + email + ' gesendet.')
		}).catch(function(error) {
		// An error happened.
		});
	}

	async loginWithGoogle(){
		return await this.auth.signInWithPopup(this.googleProvider).then(function(result) {
			// This gives you a Google Access Token. You can use it to access the Google API.
			var token = result.credential.accessToken;
			// The signed-in user info.
			var user = result.user;
			// ...
			console.log("Login", user);
			this.setState({user: result.user});
		  }).catch(function(error) {
			// Handle Errors here.
			var errorCode = error.code;
			var errorMessage = error.message;
			// The email of the user's account used.
			var email = error.email;
			// The firebase.auth.AuthCredential type that was used.
			var credential = error.credential;
			// ...
		  });
	}
	
/* 	addQuote(quote) {
		if(!this.auth.currentUser) {
			return alert('Not authorized')
		}

		const dataauth = { input: quote, uid: this.auth.currentUser.uid, createdAt: app.firestore.FieldValue.serverTimestamp() }
		return this.db.doc(`users_codedamn_video/${this.auth.currentUser.uid}`).set({
			dataauth
		})
	} */

	addMember(data, values) {
		if(!this.auth.currentUser) {
			return alert('Not authorized')
		}

		this.deleteAllMembers().then(res => {
			const dataauth = { member: data, uid: this.auth.currentUser.uid, createdAt: app.firestore.FieldValue.serverTimestamp(), inputValues: values }
/* 			const userauth ={uid:this.auth.currentUser.uid}

			const member = {
				auth: userauth,
				member: data
			}
			console.log(member) */
			return this.db.collection(`members`).add(dataauth)
		})
	}

 	async deleteAllMembers(){
		const snapshot = await this.db.collection(`members`).where("uid", "==", this.auth.currentUser.uid).get()

		if (snapshot.docs.length > 0) {
			console.log("snapshot", snapshot);
			var batch = this.db.batch();
			snapshot.docs.forEach(doc => {
				console.log("doc", doc);
				var laRef = this.db.collection("members").doc(doc.id);
				batch.delete(laRef);
			})
			batch.commit();
		}
	 }

	isInitialized() {
		return new Promise(resolve => {
			this.auth.onAuthStateChanged(resolve)
		})
	}

	getCurrentUsername() {
		console.log("getCurrentUsername", this.auth.currentUser);
		return this.auth.currentUser && this.auth.currentUser.displayName
	}

	async getCurrentUserQuote() {
		const quote = await this.db.doc(`users_codedamn_video/${this.auth.currentUser.uid}`).get()
		return quote.get('quote')
	}

	async getCurrentUserMember() {
		if(!this.auth.currentUser) {
			return alert('Not authorized')
		}

		var allMembers = [];
		var values ={startnr: '', startanz:'', check1:false, check2:false, check3:false,  
			car:'', cardate:'', carnr: '', licence1:false, licence2:false, licence3:false, 
			childrencount:0, childrenage:'', memcount:0,
			costs:'',childrencosts:'', total:''
		};
		console.log("TEST", this.auth.currentUser.uid);
		const snapshot = await this.db.collection(`members`).where("uid", "==", this.auth.currentUser.uid).get()

		if (snapshot.docs.length > 0) {
			snapshot.docs.forEach(doc => {
				// doc is a DocumentSnapshot with actual data
				const data = doc.data();
				
				data.member.forEach(mem=>{
					console.log("Member = ", mem);
					allMembers.push(mem);
				})
				values=data.inputValues;
			})
		}
		else {
			// decide what you want to do if there are no documents returned from the query
		}		

		console.log("allMembers", allMembers);
		return {members: allMembers, inputValues: values};
		//return snapshot.docs.map(doc => doc.data());

/* 		console.log("TEST", this.auth.currentUser.uid);
		const data = await this.db.collection(`members`).doc(this.auth.currentUser.uid).get()
		console.log("Current data: ", data);
 */		
/* 		db.collection("cities").doc("SF")
		.onSnapshot(function(doc) {
			console.log("Current data: ", doc.data());
		}); */
/* 		db.ref("all_notes/0001").on("value", snapshot => {
			let allNotes = [];
			snapshot.forEach(snap => {
			  allNotes.push(snap.val());
			});
			this.setState({ notes: allNotes });
		  });
 */		//return data
	}

	async getOrgaData() {
		if(!this.auth.currentUser) {
			return alert('Not authorized')
		}

		console.log("currentUser.uid", this.auth.currentUser.uid);
		const snapshot = await this.db.collection(`members`).doc('7R7CyoZirBnH82q4ZdSj').get()
		console.log("Doc", snapshot);

		var allOrgData = [];
				// doc is a DocumentSnapshot with actual data
				const data = snapshot.data();
				console.log("OrgData", data);

				data.tasks.forEach(item=>{
					console.log("Item = ", item);
					allOrgData.push(item);
				})


		return allOrgData;
	}

	async updateOrgData(data) {
		if(!this.auth.currentUser) {
			return alert('Not authorized')
		}

		var orgaRef = this.db.collection(`members`).doc('7R7CyoZirBnH82q4ZdSj');
		return orgaRef.update({
			tasks: data
		})
		.then(function() {
			console.log("Document successfully updated!");
		})
		.catch(function(error) {
			// The document probably doesn't exist.
			console.error("Error updating document: ", error);
		});
	}

	async getStationData(id) {
		if(!this.auth.currentUser) {
			return alert('Not authorized')
		}

		console.log("currentUser.uid", this.auth.currentUser.uid);
		const snapshot = await this.db.collection(`members`).doc(id).get()
		console.log("Doc", snapshot);

		var allStationData = [];
		// doc is a DocumentSnapshot with actual data
		const data = snapshot.data();
		console.log("StationData", data);

		data.teams.forEach(item=>{
			console.log("Item = ", item);
			allStationData.push(item);
		})
		
		return allStationData;
	}

	async updateStationData(data, id) {
		if(!this.auth.currentUser) {
			return alert('Not authorized')
		}
		
		var orgaRef = this.db.collection(`members`).doc(id);
		return orgaRef.update({
			teams: data
		})
		.then(function() {
			console.log("Document successfully updated!");
		})
		.catch(function(error) {
			// The document probably doesn't exist.
			console.error("Error updating document: ", error);
		});
	}
}

export default new Firebase()