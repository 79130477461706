import React, { useState, useEffect } from 'react'
import './styles.css'
import HomePage from '../HomePage'
import Login from '../Login'
import Register from '../Register'
import Dashboard from '../Dashboard'
import Footer from "../Footer";
import Impressum from '../impressum'
import Datenschutz from '../datenschutz'
import OrgaPage from '../OrgaPage'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { CssBaseline, CircularProgress } from '@material-ui/core'
import { HashRouter, Switch, Route } from 'react-router-dom'
import firebase from '../firebase'
import "bootstrap/dist/css/bootstrap.min.css";
import Station11 from '../Stations/Station11'
import Station12 from '../Stations/Station12'
import Result1 from '../result1';
import Result2 from '../result2';
import Result12 from '../result12';

const theme = createMuiTheme()

export default function App() {

	const [firebaseInitialized, setFirebaseInitialized] = useState(false);

	useEffect(() => {
		firebase.isInitialized().then(val => {
			setFirebaseInitialized(val)
		});
	})

	return firebaseInitialized !== false ? (
		<MuiThemeProvider theme={theme}>
			<CssBaseline />
			<HashRouter history={History}>
				<Switch>
					<Route exact path="/" component={HomePage} />
					<Route exact path="/login" component={Login} />
					<Route exact path="/register" component={Register} />
					<Route exact path="/dashboard" component={Dashboard} />
					<Route exact path="/impressum" component={Impressum} />
					<Route exact path="/datenschutz" component={Datenschutz} />
					<Route exact path="/orga" component={OrgaPage} />
					<Route exact path="/stationen/station1-1" component={Station11} />
					<Route exact path="/stationen/station1-2" component={Station12} />	
					<Route exact path="/result1" component={Result1} />
					<Route exact path="/result2" component={Result2} />
					<Route exact path="/result12" component={Result12} />
				</Switch>
				<Footer />
			</HashRouter>
		</MuiThemeProvider>
	) : <div id="loader"><CircularProgress /></div>
}